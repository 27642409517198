<template>
  <div class="bg-white p-5 hv-100">
      <center><img width="150px" alt="BXI" src="@/assets/images/barter-logo.png"></center>
      <br><br>
      <div class="px-2" v-if="!bxiUser">
          <h5>Login to your BXI account</h5>
          <br>
          <span>Account name</span><br>
          <input class="form-control mt-2" style="font-size: 90%;" placeholder="barter" type="text" v-model="accountName"/>
          <br>
          <span>Email address</span><br>
          <input class="form-control mt-2" style="font-size: 90%;" placeholder="user@barter.com" type="text" v-model="email"/>
          <br>
          <span>Password</span><br>
          <input class="form-control mt-2" style="font-size: 90%;" placeholder="************" type="password" v-model="password"/>
          <br>
          <div class="btn btn-primary btn-sm rounded-0 p-3 px-4 mt-3" style="width: 100%" @click="loginWithBXI">
              Login and authorize
          </div>
      </div>
      <div class="px-2" v-else>
        <div v-if="itsVerified">
            <div class="btn btn-primary btn-sm rounded-0 p-3 px-4 mt-3" style="width: 100%" @click="loginWithITS">
              Welcome {{itsUser.data.user.name}}! Continue to ITS
            </div>
            <div class="btn btn-light btn-sm rounded-0 p-3 px-4 mt-3" style="width: 100%" @click="clearLogin">
                Login with another account
            </div>
        </div>
        <div v-else class="text-center">
            <div v-if="step === 1">
              <h5>
                <span class="text-primary-default">iRipple Ticketing System</span>
                wants to access your BXI Account
              </h5>
              <p>
                <i class="h4 fas fa-user-circle"></i>
                <span class="px-2">{{bxiUser.username}}</span>
              </p>
              <br>
              <p>
                This will create a new ITS user based on your BXI profile details. <span class="text-primary-default"></span>
              </p>
              <br>
              <div class="btn btn-primary btn-sm rounded-0 p-3 px-4 mt-3" style="width: 100%" @click="createNewUser">
                Authorize ITS to access your data
              </div>
              <div class="btn btn-light btn-sm rounded-0 p-3 px-4 mt-3" style="width: 100%" @click="clearLogin">
                  Login with another account
              </div>
            </div>
            <div v-else>
            <div class="form-group my-3">
              <p>
                This will be your initial password. Please change this as soon as you login to your ITS Account.
              </p>
              <br>
              <input disabled v-model="tempPassword" :type="showPassword ? `text` : `password`" placeholder="Password" class="form-control text-escalated p-4" />
              <div
                class="btn btn-muted btn-lg p-4 btn-search btn-search-end float-right"
                @mouseover="showPassword = true"
                @mouseleave="showPassword = false"
                style="position: relative; margin-top: -77.5px; z-index: 999"
              >
                <i class="text-secondary fas" :class="showPassword ? `fa-eye-slash` : `fa-eye`"/>
              </div>
            </div>
            <div class="btn btn-primary btn-sm rounded-0 p-3 px-4 mt-3" style="width: 100%" @click="loginWithITS">
              Continue to ITS
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  export default {
    data() {
      return {
        accountName: 'boutique',
        email: 'demo',
        password: 'password',
        bxiUser: null,
        itsUser: null,
        itsVerified: false,
        showPassword: false,
        tempPassword: '',
        step: 1,
        busy: true,
        clients: []
      }
    },
    methods: {
      loader: function(val){
        this.$emit('loader', val)
      },
      loginWithBXI: async function(){
        this.busy = true
        await this.getClients()
        if(!this.validateClient()._id){
          this.$alertify({
            group: 'notification',
            title: 'BXI Login Failed',
            type: 'warn',
            text: "This account is not connected to any ITS Client. Please contact your administrator for more details."
          })
          return 0
        }
        try {
          const response = await this.$http.post(
            `${this.$bxiBackEndpoint}/oauth/token`,
            {
              grant_type: "password",
              account_name: this.accountName,
              email: this.email,
              password: this.password
            },
            {}
          );
          if(response && response.data)
            this.fetchBXIUser(response.data)
          else
            this.$alertify({
              group: 'notification',
              title: 'BXI Login Failed',
              type: 'warn',
              text: "Invalid credentials. Please try again"
            })
          this.loader(false);
        } catch (error) {
          this.$alertify({
            group: 'notification',
            title: 'BXI Login Failed',
            type: 'warn',
            text: "Invalid credentials. Please try again"
          })
          console.log(error)
          this.loader(false);
        }
        this.busy = false;
      },
      fetchBXIUser: async function(auth_response){
        this.busy = true
        try {
          const response = await this.$http.get(
            `${this.$bxiBackEndpoint}/api/v2/users/me`,
            {
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${auth_response.access_token}`
              }
            }
          )
          this.bxiUser = response.data
          await this.checkITSuser(auth_response)
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.bxiUser = false
          this.loader(false);
        }
        this.busy = false;
      },
      loginWithITS: async function(){
        if(this.itsVerified){
          localStorage.setItem('manual_response', JSON.stringify(this.itsUser))
          this.initializeLogin()
          window.close()
        }
      },
      createNewUser: async function(){
        try {
          const response = await this.$http.post(
            `${this.$apiEndpoint}/v1/users`,
            {
              user: {
                name: this.bxiUser.username,
                email: this.bxiUser.username.replace("@", `+${this.bxiUser.account_name}@`),
                mobileNumber: '',
                phoneNumber: '',
                isAgent: false,
                isActive: true,
                avatar: '/images/avatars/avatarArtboard-1.svg',
                receiveEmail: true,
                password: this.bxiUser.account_id + '-' + this.bxiUser.user_id,
                confirmPassword: this.bxiUser.account_id + '-' + this.bxiUser.user_id,
                gitlabPersonalToken: '',
                roles: 'Customer',
                client: this.validateClient()._id
              }
            },
            {
              headers: {
                'Authorization': await this.$itsAdminToken(),
                'Access-Control-Allow-Origin' : '*',
              }
            }
          )
          if(response.data && response.data.user){
            this.itsUser = response
            this.itsVerified = true
            this.$alertify({
              group: 'notification',
              title: 'Login successful',
              type: 'success',
              text: 'Welcome back ' + response.data.user.name
            })
          }
        } catch (error) {
          this.loader(false)
          console.log(error)
          window.close()
        }
      },
      checkITSuser: async function(auth_response){
        this.busy = true
        this.loader(true)
        try {
          const data = {
            email: this.bxiUser.username.replace("@", `+${this.bxiUser.account_name}@`),
            account_token: `${this.bxiUser.user_id}-${this.bxiUser.account_id}`,
            access_token: auth_response.access_token
          }
          const response = await this.$http.post(
            `${this.$apiEndpoint}/v1/integrations/login`,
            data
          )
          if(response && response.data && response.data.user && response.data.user._id){
            this.itsUser = response
            this.itsVerified = true
            this.$alertify({
              group: 'notification',
              title: 'Login successful',
              type: 'success',
              text: 'Welcome back ' + response.data.user.name
            })
          }
          else{
            this.itsVerified = false
          }
        } catch (error) {
          this.busy = false
          this.itsVerified = false
          console.log(error)
        }
        this.loader(false)
      },
      initializeLogin: function(){
        const response = this.itsUser
        let userData = {
          user: response.data.user,
          authToken: response.data.token,
          gitlabToken: response.data.gitlabPersonalToken,
          login_timestamp: new Date()
        };
        localStorage.setObject('userData', userData)
        localStorage.setItem('newLogin', true)
        Vue.prototype.$userData = userData
        this.$alertify({
          group: 'notification',
          title: 'Login successful',
          type: 'success',
          text: 'Welcome back ' + response.data.user.name
        })
        return true
      },
      clearLogin: function(){
        this.bxiUser = null
        this.itsUser = null
        this.itsVerified = false
      },
      getClients: async function(){
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/clients`,
            {
              headers: {
                'Authorization': await this.$itsAdminToken(),
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.clients = response.data.clients || []
        } catch (error) {
          this.clients = []
          console.log(error)
        }
      },
      validateClient: function(){
        return this.clients.find(r => r.integrations && r.integrations.bxi && r.integrations.bxi.account_name === this.accountName) || {}
      }
    },
    created() {
      window.addEventListener('beforeunload', function() {
        localStorage.setItem('manual_close', '1')
      })
    },
    mounted: function(){
    }
  }
</script>
